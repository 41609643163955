import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import 'bootstrap/dist/js/bootstrap.min.js'; 
import 'jquery/dist/jquery.min.js'; 
import 'popper.js/dist/umd/popper.min.js'; 
import Button from 'react-bootstrap/Button';
import { Tab, Tabs } from 'react-bootstrap';
import { Collapse } from 'react-bootstrap';
import * as XLSX from 'xlsx';  // Import XLSX for Excel export
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'; // or free-regular-svg-icons if you prefer
//import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { faFacebook } from '@fortawesome/free-brands-svg-icons'; // For the Facebook brand icon

import { Routes, Route } from 'react-router-dom';
import Home from './Home'; 


function App() {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [results2, setResults2] = useState([]);
  const [error, setError] = useState('');
  const [language, setLanguage] = useState('en_US');
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPage2, setCurrentPage2] = useState(1);
  const [itemsPerPage] = useState(10);
  const [itemsPerPage2] = useState(10);
  const longTermToken = process.env.REACT_APP_FACEBOOK_ACCESS_TOKEN;
  const [showSettings, setShowSettings] = useState(false);
  const [showSearchSection, setShowSearchSection] = useState(false);  // New state for showing menu vs content
  const [showMenuSection, setShowMenuSection] = useState(true);  // New state for showing menu vs content
  const [noResultsMessage, setNoResultsMessage] = useState('');  // No results message

  
  useEffect(() => {
    document.title = "Growfox | Marketing Tools";
  }, []);

  const handleSearch = async () => {
    try {
      if (!query) {
        setError('ระบุ Keyword ที่ต้องการค้นหา'); // Show error if the search input is empty
        setNoResultsMessage('');  // Clear any previous no-results message
        return;
      }
  
      setError('');  // Clear error if input is valid
  

      const response = await axios.get(
        `https://graph.facebook.com/v20.0/search`, {
          params: {
            type: 'adinterest',
            q: query,
            access_token: longTermToken,
            limit: 1000,
            locale: language
            //, offset: offset
          }
        });
      setResults(response.data.data || []); 

      const response2 = await axios.get(
        `https://graph.facebook.com/v20.0/search`, {
          params: {
            type: 'adinterestsuggestion',
            interest_list: [query],
            access_token: longTermToken,
            limit: 1000,
            locale: language
            //,            offset: offset
          }
        });
      setResults2(response2.data.data || []);


      if (response.data.data.length === 0 && response2.data.data.length === 0) {
        setNoResultsMessage('No results found.');  // Show message if no results
      } else {
        setNoResultsMessage('');  // Clear no-results message if there are results
      }
      setCurrentPage(1);  
      setCurrentPage2(1);  

    } catch (error) {
      console.error('Error during search:', error);
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentResults = results.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(results.length / itemsPerPage);

  const indexOfLastItem2 = currentPage2 * itemsPerPage2;
  const indexOfFirstItem2 = indexOfLastItem2 - itemsPerPage2;
  const currentResults2 = results2.slice(indexOfFirstItem2, indexOfLastItem2);
  const totalPages2 = Math.ceil(results2.length / itemsPerPage2);

  const handlePageChange = (page) => setCurrentPage(page);
  const handlePageChange2 = (page) => setCurrentPage2(page);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
  };

  // Export function for the first table
  const exportToExcel1 = () => {
    const dataToExport = results.map(interest => ({
      ID: interest.id,
      Name: interest.name,
      'Audience Size (Lower)': interest.audience_size_lower_bound,
      'Audience Size (Upper)': interest.audience_size_upper_bound,
      'Path 1': interest.path[0],
      'Path 2': interest.path[1] || 'N/A',
      'Path 3': interest.path[2] || 'N/A',
      Description: interest.description || 'N/A',
      Topic: interest.topic || 'N/A'
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Ad Interests');
    XLSX.writeFile(workbook, 'ad_interests.xlsx');
  };

  // Export function for the second table
  const exportToExcel2 = () => {
    const dataToExport = results2.map(interest2 => ({
      ID: interest2.id,
      Name: interest2.name,
      'Audience Size': interest2.audience_size,
      Path: interest2.path ? interest2.path.join(' > ') : 'N/A',
      Topic: interest2.topic || 'N/A'
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Ad Interest Suggestions');
    XLSX.writeFile(workbook, 'ad_interest_suggestions.xlsx');
  };
  const menuItems = [
    {
      title: 'Facebook Interest Search',
      image: './menu_001.png',
      description: 'ค้นหา Keyword Facebook Ads Interest และ Suggestion',
      link: '#',
    },
  ];
  // Function to handle clicking the 'Go' button on menu cards
  const handleCardClick = () => {
    setShowSearchSection(true);  // Show the search section
        setShowMenuSection(false);  // Show the menu

  };

  // Function to handle clicking the navigation logo
  const handleLogoClick = () => {
    setShowSearchSection(false);  // Show the menu
    setShowMenuSection(true);  // Show the menu

    setQuery('');  // Clear the search text box
    setResults([]);  // Clear the first result list
    setResults2([]);  // Clear the second result list

  };

  
  return (
    
    <div className="App">
     
      <header className="navbar navbar-expand-lg navbar-dark bg-dark">
        <title>Growfox - Marketing Tools</title>
        <div className="container">
          <a className="navbar-brand" href="#" onClick={handleLogoClick}>
            <img src="https://growfox.co/wp-content/uploads/2023/04/Main-Logo.png" alt="Company Logo" className="company-logo" />
          </a>
         
        </div>
      </header>

      <main>
      {!showSearchSection ? (
          <Collapse in={showMenuSection}>
          <div className="container mt-4">
            <div className="row">
              {menuItems.map((item, index) => (
                <div className="col-md-4" key={index}>
                  <div className="card">
                    <img src={item.image} className="card-img-top" alt={item.title} />
                    <div className="card-body">
                      <h5 className="card-title">{item.title}</h5>
                      <p className="card-text">{item.description}</p>
                      <button onClick={handleCardClick} className="btn btn-danger">Go</button>
                    </div>
                  </div>
                </div>
            
              ))}
            </div>
          </div>
          </Collapse>
        ) : (
          // Display search section when showSearchSection is true
          <Collapse in={showSearchSection}>

          <section id="search-section" className="search-section container mt-4">
            <div className="search-container d-flex justify-content-between align-items-center">
              <input
                type="text"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleSearch();
                  }
                }}
                placeholder="Keyword ที่ต้องการค้นหา..."
                className="form-control"
                style={{ flex: 1, marginRight: '10px' }}
              />
              <button onClick={handleSearch} className="btn btn-growfox ml-2">
                ดูข้อมูล
              </button>
              <button className="btn btn-secondary ml-2" onClick={() => setShowSettings(!showSettings)}>Settings</button>
            </div>

            <Collapse in={showSettings}>
              <div className="settings-section p-3 mt-3" style={{ color: '#fff' }}>
                <label htmlFor="language">Language</label>
                <select value={language} onChange={(e) => setLanguage(e.target.value)} className="form-control">
                  <option value="en_US">English (US)</option>
                  <option value="th_TH">Thai</option>
                </select>
              </div>
            </Collapse>

            {error && <p className="text-danger mt-3">{error}</p>}
            {noResultsMessage && <p className="text-warning mt-3">{noResultsMessage}</p>}

          <Tabs defaultActiveKey="results1" id="result-tabs" className="nav-tabs mt-3"> 
    <Tab eventKey="results1" title="Ads Interests" className="nav-link" >
      {results.length > 0 && (
        <>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <label htmlFor="language" style={{ color: '#ff6000' }}>
              Total Records: {results.length}
            </label>

            <div className="d-flex justify-content-between align-items-center mb-3 div_padding text-sitetheme">
        <nav>
        <ul className="pagination pagination-sm mb-0 custom-pagination">
        {Array.from({ length: totalPages }, (_, index) => (
          <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
            <Button
              className="page-link"
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </Button>
          </li>
        ))}
      </ul>


        </nav>
      </div>
          </div>

          <div className="table-responsive">
            <table className="table table-dark table-striped">
              <thead>
                <tr>
                  <th>#</th>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Audience Size (Lower)</th>
                  <th>Audience Size (Upper)</th>
                  <th>Path 1</th>
                  <th>Path 2</th>
                  <th>Path 3</th>
                  <th>Description</th>
                  <th>Topic</th>
                  <th>Disambiguation Category</th>

                </tr>
              </thead>
              <tbody>
                {currentResults.map((interest, index) => (
                  <tr key={interest.id}>
                    <td>{indexOfFirstItem + index + 1}</td>
                    <td>{interest.id}</td>
                    <td style={{ textAlign: 'left' }}>{interest.name}</td>
                    <td>{interest.audience_size_lower_bound}</td>
                    <td>{interest.audience_size_upper_bound}</td>
                    <td>{interest.path[0]}</td>
                    <td>{interest.path[1] || 'N/A'}</td>
                    <td>{interest.path[2] || 'N/A'}</td>
                    <td>{interest.description || 'N/A'}</td>
                    <td>{interest.topic || 'N/A'}</td>
                    <td>{interest.disambiguation_category || 'N/A'}</td>        
                  </tr>
                ))}
              </tbody>
            </table>
            <Button onClick={exportToExcel1} className="ml-auto" style={{ backgroundColor: '#28a745', color: '#fff' }}>
              <FontAwesomeIcon icon={faFileExcel} style={{ marginRight: '5px' }} />
              Export to Excel
            </Button>
          </div>
        </>
        
      )}

     
    </Tab>

  <Tab eventKey="results2" title="Ads Interest Suggestions" className="nav-link">
    {results2.length > 0 && (
      <>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <label htmlFor="language" style={{ color: '#ff6000' }}>
            Total Records: {results2.length}
          </label>

          <div className="d-flex justify-content-between align-items-center mb-3 div_padding text-sitetheme">
      <nav>
      <ul className="pagination pagination-sm mb-0 custom-pagination">
        {Array.from({ length: totalPages }, (_, index) => (
          <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
            <Button
              className="page-link"
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </Button>
          </li>
        ))}
      </ul>

      </nav>
    </div>
        
        </div>

        <div className="table-responsive">
          <table className="table table-dark table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>ID</th>
                <th>Name</th>
                <th>Audience Size</th>
                <th>Path</th>
              </tr>
            </thead>
            <tbody>
              {currentResults2.map((interest2, index) => (
                <tr key={interest2.id}>
                  <td>{indexOfFirstItem2 + index + 1}</td>
                  <td>{interest2.id}</td>
                  <td style={{ textAlign: 'left' }}>{interest2.name}</td>
                  <td>{interest2.audience_size}</td>
                  <td style={{ textAlign: 'left' }}>{interest2.path ? interest2.path.join(' > ') : 'N/A'}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <Button onClick={exportToExcel2} className="ml-auto" style={{ backgroundColor: '#28a745', color: '#fff' }}>
            <FontAwesomeIcon icon={faFileExcel} style={{ marginRight: '5px' }} />
            Export to Excel
          </Button>
        </div>
      </>
      

    )}

  </Tab>
          </Tabs>

        </section>
        </Collapse>
         )}
      </main>
    </div>
  );
}

export default App;
